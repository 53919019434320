<template>
    <styled-interface>
        <template #interface-toolbar>
            <dealer-picker-global class="flex md3 xs12" />
        </template>
        <legal-agreements-dialog
            v-if="currentDealer"
            v-model="legalAgreementsDialogActive"
            :dealer="currentDealer" />

        <styled-slideout
            v-model="fieldDescriptionsSlideoutOpen"
            :width="800">
            <div class="pa-5">
                <h2 class="mb-4">
                    Field Descriptions
                </h2>
                <table class="styled-table-native">
                    <thead>
                        <tr>
                            <th style="width: 20%">
                                Field
                            </th>
                            <th style="width: 30%">
                                Required
                            </th>
                            <th style="width: 50%">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(field) in csvFields"
                            :key="field.field">
                            <td class="dark-column">
                                <strong>{{ field.field }}</strong>
                            </td>
                            <td>{{ field.required }}</td>
                            <td>{{ field.description }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </styled-slideout>

        <ads-upsell
            v-if="!dealerHasAdProduct"
            :dealer="currentDealer" />

        <template v-else>
            <limited-data-usage-alert
                class="mb-4"
                @click="legalAgreementsDialogActive = true" />

            <styled-card class="mb-4">
                <template #heading>
                    Upload Offline Events
                </template>
                <!-- start instructions-of-usage  -->
                <div
                    ref="intructions-ref"
                    class="mt-2 px-5">
                    <p class="intructions-description pt-4">
                        Use the form below to upload offline customer data such as leads and sales to enable matchback
                        reporting which allows you to attribute Meta advertising spend to offline activity providing
                        a true depiction of your return on investment.<br>
                        The following pieces of data are required to be included in your Offline Event CSV upload for
                        the system to be able to process your matchbacks: Full name or First/Last name and an email or
                        phone number.
                    </p>
                    <!-- @todo re-enable this once we have content
                    <div
                        class="flex layout justify-center align-center lg12 md12 xs12 pt-4 section-column">
                        <div class="flex layout lg10 md10 sm12">
                            <p class="intructions-description">
                                Use the form below to upload offline customer data such as leads
                                and sales to enable matchback reporting which allows you to
                                attribute Meta advertising spend to offline activity
                                providing a true depiction of your return on investment.
                            </p>
                        </div>

                        <div class="flex layout justify-end lg3 md12 sm12">
                            <a
                                class="watch-more-btn d-flex"
                                @click.prevent="watchMoreHelpSection = true">
                                <i class="v-icon material-icons play-icon mx-2">play_arrow</i>
                                <p class="watch-more-text">Watch more about this</p>
                            </a>
                        </div>
                    </div>
                    -->
                    <v-divider class="mt-4" />
                    <div v-if="showHelpSection">
                        <div class="flex layout md12 xs12 mt-5 section-column">
                            <div class="flex md4 pa-3">
                                <v-layout class="column align-center justify-center">
                                    <div class="instruction-step">
                                        <p>1</p>
                                    </div>
                                    <div class="medium-image-v">
                                        <img
                                            src="/img/offline-events-step1.png"
                                            alt="CRM">
                                    </div>
                                    <div class="text-center">
                                        <h3 class="py-3">
                                            Download customer data
                                        </h3>
                                        <p>
                                            Login to your CRM or data provider and export a list of
                                            recent transactions that you want to use to evaluate ad
                                            performance
                                        </p>
                                    </div>
                                </v-layout>
                            </div>
                            <v-divider vertical />
                            <div class="flex md4 pa-3">
                                <v-layout class="column align-center justify-space-around">
                                    <div class="instruction-step">
                                        <p>2</p>
                                    </div>
                                    <div class="d-flex justify-center align-center medium-image">
                                        <img
                                            src="/img/offline-events-step2.png"
                                            alt="Prepare and Upload">
                                    </div>
                                    <div class="text-center">
                                        <h3 class="py-3">
                                            Prepare &amp; upload
                                        </h3>
                                        <p>
                                            Once the CRM export has been completed, use the tool below to select or drag
                                            and drop your exported data to be uploaded
                                        </p>
                                    </div>
                                </v-layout>
                            </div>
                            <v-divider vertical />
                            <div class="flex md4 pa-3">
                                <v-layout class="column align-center justify-space-around">
                                    <div class="instruction-step">
                                        <p>3</p>
                                    </div>
                                    <div class="d-flex justify-center align-center medium-image">
                                        <img
                                            src="/img/offline-events-step3.png"
                                            alt="View results">
                                    </div>

                                    <div class="text-center">
                                        <h3 class="py-3">
                                            View results
                                        </h3>
                                        <p>
                                            Once we're done processing the file, head over to the
                                            <router-link
                                                :to="{
                                                    name: 'reports-facebook-advertising',
                                                    params: { dealer_id: currentDealer.id}
                                                }">
                                                Meta advertising report
                                            </router-link>
                                            to see how well you did!
                                        </p>
                                    </div>
                                </v-layout>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <a
                            v-if="showHelpSection"
                            class="d-flex justify-end"
                            @click.prevent="showHelpSection = false">Hide this</a>
                        <a
                            v-else
                            class="d-flex justify-end"
                            @click.prevent="showHelpSection = true">Show steps</a>
                        <v-divider
                            v-if="showHelpSection"
                            class="my-4" />
                    </div>
                </div>
                <help-section-dialog v-model="watchMoreHelpSection" />
                <!--End instructions-of-usage  -->
                <div
                    v-if="!dealerFacebookOfflineEventSetAnnotation"
                    ref="deploying"
                    class="pa-4">
                    <v-alert
                        :value="true"
                        type="error">
                        You must associate an offline event set with this dealer to use this
                        tool. Please onboard an advertising playbook which will
                        automatically create this association. Or if you think you're seeing
                        this message in error please contact support.
                    </v-alert>
                </div>
                <div
                    v-else
                    class="pt-4 pb-5 px-5">
                    <div
                        v-if="csvDeploying"
                        ref="warningsAndErrors">
                        <h4>Your file has been analyzed</h4>
                        <p>
                            Please review your files results below. As part of our CSV analysis, we sample rows from
                            your CSV
                            file to provide an Estimated Match Rate. We also sample rows throughout your CSV file to
                            look for
                            warnings and errors that are present to display them below. In many cases a few errors and
                            warnings
                            can still process successfully.
                        </p>

                        <p
                            v-if="fatalApiError && fileRows.length < 5000"
                            class="fatal-error">
                            Fatal error: "We are unable to process your Offline Events due to a fatal error.
                            Please review your CSV file to ensure that there are no duplicate header names and rows
                            or columns that contain data that are not Offline Events. Also, ensure that all required
                            columns are mapped correctly. If the issues persists, please upload your file directly
                            within Facebook."
                        </p>
                        <p
                            v-if="fatalApiError && fileRows.length >= 5000"
                            class="fatal-error">
                            Upload Limit: "The amount of data in your CSV file is not able to be processed by Facebook
                            at this time.
                            Please split your Offline Event set into smaller files with less rows and try again. If the
                            issue persists,
                            please upload your file directly within Facebook."
                        </p>

                        <div
                            v-if="!fatalApiError"
                            class="flex layout row md12 xs12 justify-space-between">
                            <styled-callout class="flex layout md9 xs12 mb-3">
                                <div
                                    class="flex layout md12 justify-space-between align-center xs12 mb-3">
                                    <div class="flex md5 xs12 uploaded-file-wrap">
                                        <p>Uploaded file:</p>
                                        <span
                                            class="file-csv-icon"><icon name="file-csv" /> {{ fileCsvName }}</span>
                                    </div>
                                    <div class="flex md3 xs12 file-rows-status">
                                        <p>File row results:</p>
                                        <a class="file-rows-success">
                                            Successful({{ successFulUploadRows }})</a>
                                    </div>
                                    <div class="flex md3 xs12 file-rows-status">
                                        <a class="file-rows-warnings">
                                            Warnings({{ uploadCsvWarnings.length }})</a>
                                    </div>
                                    <div class="flex md3 xs12 file-rows-status">
                                        <a
                                            class="file-rows-errors"
                                            @click="viewingErrorsFatal == true">
                                            Errors({{ uploadCsvErrors.length }})</a>
                                    </div>
                                    <div class="flex md2 xs12 justify-center align-center">
                                        <a @click="seeErrorDetails = true"><h3>See Details</h3></a>
                                    </div>
                                </div>
                            </styled-callout>


                            <styled-callout
                                v-if="estimatedMatchRate"
                                class="flex layout justify-center align-center column md3 xs12 mb-3">
                                <styled-slideout
                                    v-model="showEstimatedMatchRateSlide"
                                    :width="600">
                                    <div class="pa-3 mx-3">
                                        <h3 class="mb-2">
                                            The estimated match rate is generated by Facebook and represents the
                                            approximate percentage of valid records
                                            in your spreadsheet that match to users on Facebook.”
                                        </h3>
                                    </div>
                                </styled-slideout>
                                <div class="match_rate">
                                    <span>Estimated Match Rate</span>
                                    <div v-if="fileRows.length > 100">
                                        <h1>{{ estimatedMatchRate }}%</h1>
                                        <a
                                            href="#"
                                            @click="showEstimatedMatchRateSlide = true">What does this mean?</a>
                                    </div>
                                    <div v-else>
                                        <h3>Over 100 events needed for match rating</h3>
                                    </div>
                                </div>
                            </styled-callout>


                            <styled-callout
                                class="flex layout justify-center align-center column md3 xs12 mb-3">
                                <styled-slideout
                                    v-model="showRatingSystemSlide"
                                    :width="600">
                                    <div class="pa-3 mx-3">
                                        <h3 class="mb-2">
                                            Rating Descriptions
                                        </h3>
                                        <p>
                                            Use the designations below to understand how we rate
                                            uploads. The purpose of our rating system is to help
                                            you understand how to get better match rates which,
                                            typically, lead to higher attribution in reporting.
                                        </p>
                                    </div>
                                    <div class="pl-5 mb-3">
                                        <h2>
                                            Awesome
                                        </h2>
                                        <p>
                                            No errors and less than 5% warnings
                                        </p>
                                    </div>
                                    <div class="pl-5 mb-3">
                                        <h2>
                                            Good
                                        </h2>
                                        <p>
                                            Less than 5% errors and less than 10% warnings
                                        </p>
                                    </div>
                                    <div class="pl-5 mb-3">
                                        <h2>
                                            Fair
                                        </h2>
                                        <p>
                                            Less than 10% errors and less than 20% warnings
                                        </p>
                                    </div>
                                    <div class="pl-5 mb-3">
                                        <h2>
                                            Poor
                                        </h2>
                                        <p>
                                            Everything else
                                        </p>
                                    </div>
                                </styled-slideout>
                                <span>Rating</span>
                                <h1>{{ fileUploadedRating }}</h1>
                                <h4>
                                    <a
                                        @click="showRatingSystemSlide = true">What does this mean?</a>
                                </h4>
                            </styled-callout>
                        </div>
                        <div class="flex layout column align-center justify-center mt-5">
                            <styled-button
                                v-if="!fatalApiError"
                                class="text-uppercase"
                                :loading="csvDeployLoading"
                                @click="deployReviewedCsvFile()">
                                CONTINUE PROCESSING
                            </styled-button>
                            <a
                                class="play-button ml-3 my-3"
                                @click="reset()">
                                Upload new file
                            </a>
                        </div>
                        <div v-if="seeErrorDetails">
                            <div ref="errorDetails" />
                            <span
                                class="feature-status-heading heading-darkorange">Rows Results
                            </span>
                            <div class="layout my-2">
                                <div class="flex xs12 md3 ml-3 align-center">
                                    Type
                                </div>
                                <div class="feature-status-error flex xs12 md3">
                                    Row
                                </div>
                                <div class="feature-status-error flex xs12 md3">
                                    Status
                                </div>
                                <div class="feature-status-error flex xs12 md3">
                                    Data
                                </div>
                            </div>
                            <div v-if="uploadCsvErrors.length">
                                <template v-if="viewingErrorsFatal">
                                    <div
                                        v-for="(feature, index) in uploadCsvErrors"
                                        :key="`error-${index}`"
                                        :class="[
                                            'feature-status layout mb-1',
                                            index % 2 ? 'even' : 'odd',
                                        ]">
                                        <div
                                            class="feature-status-error flex xs12 md3 ml-3 align-center">
                                            <status-icon
                                                color="red"
                                                value="warning"
                                                size="15" />
                                            <span class="ml-3">{{ feature.level | capitalize }}</span>
                                        </div>
                                        <div class="feature-status-error flex xs12 md3">
                                            {{ feature.row }}
                                        </div>
                                        <div class="feature-status-error flex xs12 md6">
                                            <div>
                                                <ul>
                                                    <li
                                                        v-for="(error, i) in feature.errors"
                                                        :key="`${i}-${error}`">
                                                        {{ error.replace(/\.$/, "") }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="feature-status-error flex xs12 md3">
                                            <div>
                                                <ul class="warning_error_data_list">
                                                    <li
                                                        v-for="(data, i) in feature.data"
                                                        :key="`${i}-${data}`">
                                                        {{ data }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div v-if="uploadCsvWarnings.length">
                                <template v-if="viewingErrorsWarning">
                                    <div
                                        v-for="(feature, index) in uploadCsvWarnings"
                                        :key="`warning-${index}`"
                                        :class="[
                                            'feature-status layout mb-1',
                                            index % 2 ? 'even' : 'odd',
                                        ]">
                                        <div
                                            class="feature-status-error flex xs12 md3 ml-3 align-center">
                                            <status-icon
                                                color="darkorange"
                                                value="warning"
                                                size="15" />
                                            <span class="ml-3">{{ feature.level | capitalize }}</span>
                                        </div>
                                        <div class="feature-status-error flex xs12 md3">
                                            {{ feature.row }}
                                        </div>
                                        <div class="feature-status-error flex xs12 md3">
                                            <div>
                                                <ul>
                                                    <li
                                                        v-for="(error, i) in feature.errors"
                                                        :key="`${i}-${error}`">
                                                        {{ error.replace(/\.$/, "") }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="feature-status-error flex xs12 md3">
                                            <div>
                                                <ul class="warning_error_data_list">
                                                    <li
                                                        v-for="(data, i) in feature.data"
                                                        :key="`${i}-${data}`">
                                                        {{ data }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <v-form
                        v-else
                        ref="form"
                        v-model="valid"
                        @submit.prevent="() => false">
                        <h3 class="pb-3">
                            Upload your events
                        </h3>
                        <p class="mb-5">
                            Use the form below to upload offline customer data such as leads and sales to enable
                            matchback
                            reporting which allows you to attribute Meta advertising spend to offline activity
                            providing
                            a true depiction of your return on investment.<br>
                            The following pieces of data are required to be included in your Offline Event CSV upload
                            for
                            the system to be able to process your matchbacks: Full name or First/Last name and an email
                            or phone number.
                        </p>
                        <div class="flex layout align-center wrap mb-4">
                            <div class="flex xs12 md2">
                                <v-select
                                    v-model="eventName"
                                    :items="eventTypes"
                                    label="Event Type"
                                    class="styled-field"
                                    flat
                                    hide-details
                                    small
                                    dense
                                    :rules="requiredRules" />
                            </div>
                            <div class="flex xs12 md1 ml-4">
                                <v-select
                                    v-model="headerRow"
                                    :items="headerRows"
                                    label="Header Row"
                                    class="styled-field"
                                    flat
                                    hide-details
                                    small
                                    dense
                                    :rules="requiredRules" />
                            </div>
                            <div class="flex xs12 md2  ml-4">
                                <v-select
                                    v-model="presetFieldMapping"
                                    :items="presetFieldMappings"
                                    label="Preset Field Mapping"
                                    class="styled-field"
                                    flat
                                    hide-details
                                    small
                                    dense />
                            </div>
                            <div
                                v-if="presetSupportArticle && !userIsClient"
                                class="d-flex xs12 md3 ml-3">
                                <i class="v-icon material-icons play-icon mx-2 ">menu_book</i>
                                <a
                                    target="_blank"
                                    class="see-guide-link"
                                    :href="presetSupportArticle">See the guide book for this preset</a>
                            </div>
                            <div class="flex xs12 md3 ml-auto">
                                <div class="d-flex layout justify-end align-center">
                                    <v-checkbox
                                        v-model="sendNotification"
                                        class="styled-checkbox"
                                        color="primary">
                                        <template #label>
                                            <span
                                                class="mr-1 send-notification-text">Send me a Notification</span>
                                            <notification-emails
                                                v-model="emails"
                                                :custom-text="'(Add Recipients)'"
                                                :show-current-user-email="false" />
                                        </template>
                                    </v-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="upload-offline-events-container">
                            <file-drop
                                v-model="file"
                                :types="['csv']"
                                :header="false"
                                parse-file
                                @file-parsed="onFileParsed">
                                <template #placeholder>
                                    When uploading the extracted offline event CSV data, ensure that there are not any
                                    columns that include an empty
                                    header name and that there are not duplicate header names as they will cause the
                                    event upload to not load your CSV file.
                                    <a
                                        @click.stop
                                        @click="fieldDescriptionsSlideoutOpen = true">Review our field descriptions</a>
                                    for additional information on header field names and for example header field names
                                    that can be used
                                </template>
                            </file-drop>

                            <div
                                v-if="csvDeployLoading"
                                class="mt-2">
                                <v-progress-linear
                                    height="8"
                                    :value="csvDeployProgress"
                                    class="mt-2 mb-0"
                                    striped />
                                <p>
                                    {{ csvDeployProgressMessage }}
                                </p>
                            </div>

                            <div class="layout mt-4 mb-5">
                                <div class="flex xs12 md8">
                                    <p class="help-text">
                                        File must be in CSV format.
                                    </p>
                                </div>
                            </div>

                            <v-divider class="my-4" />
                            <upload-mapper
                                v-if="fileHeaders.length"
                                :preset="presetFieldMapping"
                                :rows="fileRows"
                                :fields="csvFields"
                                :headers="fileHeaders"
                                :mapping.sync="fileMapping"
                                :file-name="fileName"
                                @update:unhandled-fields="fileUnhandledFields = $event" />
                            <div class="flex layout column justify-center align-center">
                                <v-checkbox
                                    v-model="eventsAgreementVerified"
                                    color="primary"
                                    :rules="requiredRules">
                                    <template #label>
                                        I agree to the {{ currentAgency.name }}
                                        <a
                                            class="ml-1"
                                            :href="dataUsageLink"
                                            target="_blank">
                                            <span>data usage policies</span>
                                        </a>.
                                    </template>
                                </v-checkbox>
                                <styled-button
                                    :disabled="!eventsAgreementVerified || !file || !valid"
                                    :loading="csvDeployLoading"
                                    @click="deployNewCsvFile()">
                                    <p class="validate-process-btn">
                                        Validate and Process
                                    </p>
                                </styled-button>
                                <styled-dialog
                                    v-model="showUnhandledFieldsDialog"
                                    :width="700">
                                    <div class="flex layout column mx-5">
                                        <h1 class="unmapped-headline">
                                            Just checking
                                        </h1>
                                        <p class="mt-2 unmapped-sub-headline">
                                            We noticed that you have some un-mapped columns
                                        </p>
                                        <p class="mb-5 checking-message ">
                                            This may well be your intention so we just want to confirm
                                            that the below columns you’d like to leave unmapped.
                                        </p>

                                        <div
                                            class="mb-5 unmapped-column-list">
                                            <p class="layout column">
                                                Unmapped Columns:
                                            </p>
                                            <div class="flex layout column">
                                                <p
                                                    v-for="(field, i) in fileUnhandledFields.slice(0,4)"
                                                    :key="i">
                                                    {{ field }}
                                                </p>
                                                <a v-if="fileUnhandledFields.length > 4">
                                                    {{ fileUnhandledFields.length - 4 }} more
                                                </a>
                                            </div>
                                        </div>

                                        <div class="flex layout justify-space-around">
                                            <styled-button
                                                @click="showUnhandledFieldsDialog = false">
                                                NO I MADE A MISTAKE
                                            </styled-button>
                                            <styled-button @click="deployCsvFileWithUnhandledFields()">
                                                CONFIRM
                                            </styled-button>
                                        </div>
                                    </div>
                                </styled-dialog>
                            </div>
                        </div>
                    </v-form>
                </div>
            </styled-card>
            <styled-card>
                <template #heading>
                    Upload Status
                </template>
                <template #action-buttons>
                    <action-button
                        icon="refresh"
                        :loading="loadingEvents"
                        @click="getEvents">
                        Refresh
                    </action-button>
                </template>

                <div ref="scrollTarget" />

                <div
                    v-if="loadingEvents"
                    class="text-xs-center py-5">
                    <loader />
                </div>

                <v-alert
                    :value="csvDeploySuccessful"
                    type="success"
                    class="mt-4 mx-4 mb-5">
                    Your file was successfully uploaded and sent to Facebook you can
                    monitor the progress of your upload at the table below if you chose to
                    be notified we will send you an email as soon as Facebook is finished
                    processing the events!
                </v-alert>

                <v-alert
                    :value="uploadsContainEvents"
                    type="success"
                    class="flex xs12 mt-4 mx-4 mb-5">
                    <div class="layout align-center wrap">
                        <div class="flex xs12 md9">
                            Congrats! We've successfully processed your events and you can now
                            evaluate attribution performance on our Facebook reports (if
                            available)!
                        </div>
                        <div class="flex xs12 md3 text-md-right">
                            <styled-button
                                class=""
                                :to="{
                                    name: 'reports-facebook-advertising',
                                    params: {
                                        dealer_id: currentDealer.id,
                                    },
                                    query: {
                                        view_offline_events: true,
                                    },
                                }"
                                small
                                outline
                                light>
                                VIEW PERFORMANCE
                            </styled-button>
                        </div>
                    </div>
                </v-alert>
                <div
                    v-show="!loadingEvents && monthAndData.length"
                    class="upload-status-wrapper">
                    <div class="layout row">
                        <div
                            v-for="(month, i) in monthAndData"
                            :key="i"
                            class="upload-status-month"
                            :style="[
                                {
                                    width: '100%',
                                    position: 'relative',
                                    'background-color': '#E8E8E8',
                                },
                                month.noUpload
                                    ? { 'background-color': `${month.fill}` }
                                    : month.preUpload
                                        ? { 'background-color': `${month.fill}` }
                                        : null,
                            ]">
                            <div
                                v-if="month.isLastUpload && month.noDataFor"
                                :style="[{ position: 'relative' }]"
                                class="upload-status-month"
                                :class="{ isLastUpload: monthsWithoutUpload < 6 }">
                                <div
                                    v-if="monthsWithoutUpload >= 6"
                                    class="last-upload-more-six-months">
                                    <span><icon
                                        name="chevron-left"
                                        color="red"
                                        size="15"
                                        class="mr-1" />
                                        Last Upload {{ lastUpload }}</span>
                                </div>
                                <div
                                    v-else
                                    class="lastUpload">
                                    Last Upload
                                    <p>{{ lastUpload }}</p>
                                </div>
                            </div>

                            <div
                                v-if="month.isToday"
                                :style="[{ position: 'relative' }]">
                                <span class="upload-status-month isToday" />
                                <span class="todayText">TODAY</span>
                            </div>
                            <div
                                v-if="month.noDataFor"
                                class="upload-status-month halfOfPeriod"
                                :style="[{ position: 'relative' }]">
                                <div class="no-data-for">
                                    <div class="no-data-text">
                                        No data for
                                        <span
                                            v-if="monthsWithoutUpload >= 6">+{{
                                            monthsWithoutUpload
                                        }} months</span><span v-else>{{ monthsWithoutUpload }} months</span>
                                    </div>
                                    <a
                                        class="play-button ml-3 my-3"
                                        @click="reset()">Upload File</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="layout row align-center justify-center">
                        <div
                            v-for="(item, idx) in monthAndData"
                            :key="idx"
                            :style="[
                                { width: '100%', 'text-align': 'center', padding: '5px' },
                            ]">
                            {{ item.month }}
                        </div>
                    </div>
                </div>
                <custom-table
                    id="upload-status-table"
                    name="upload-status-table"
                    min-width="1200px"
                    :items="events"
                    :fields="fields"
                    :rows-per-page="50"
                    :loading="loadingEvents"
                    :notification="notification"
                    :notification-type="notificationType"
                    slim-cells />
            </styled-card>
        </template>
    </styled-interface>
</template>

<script>
import NotificationEmails from '@/components/globals/NotificationEmails';
import DealerPickerGlobal from '@/components/globals/DealerPickerGlobal';
import { requiredRules } from '@/helpers/validationRules';
import AdsUpsell from '@/components/globals/AdsUpsell';
import StyledInterface from '@/components/globals/StyledInterface';
import Icon from '@/components/globals/Icon';
import StyledCard from '@/components/globals/StyledCard';
import StyledSlideout from '@/components/globals/StyledSlideout';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import Loader from '@/components/globals/Loader';
import { mapGetters, mapState } from 'vuex';
import FileDrop from '@/components/globals/FileDrop';
import StyledButton from '@/components/globals/StyledButton';
import StatusIcon from '@/components/globals/StatusIcon';
import LegalAgreementsDialog from '@/components/globals/DealerLegalAgreementsDialog';
import LimitedDataUsageAlert from '@/components/globals/DealerLimitedDataUsageAlert';
import StyledCallout from '@/components/globals/StyledCallout';
import HelpSectionDialog from './HelpSectionDialog';
import UploadMapper from '@/components/globals/UploadMapper';
import StyledDialog from '@/components/globals/StyledDialog';
import moment from 'moment';
import { isEmpty, keyBy, merge, values } from 'lodash';
import csvFields from '@/data/offline-events-fields';
import presetSupportArticles from '@/data/offline-events-pre-mapped-field-links.json';
import { executeWithExponentialBackoff } from '@/helpers/functionWithExpotenialBackoff';
import ActionButton from '@/components/globals/ActionButton.vue';

export default {
    name: 'OfflineEvents',
    title: 'Offline Events',
    components: {
        ActionButton,
        Icon,
        AdsUpsell,
        DealerPickerGlobal,
        StyledInterface,
        StyledCard,
        CustomTable,
        Loader,
        StyledSlideout,
        FileDrop,
        StyledButton,
        StatusIcon,
        StyledCallout,
        LegalAgreementsDialog,
        LimitedDataUsageAlert,
        HelpSectionDialog,
        UploadMapper,
        StyledDialog,
        NotificationEmails,
    },
    data() {
        return {
            eventTypes: [
                'Purchase',
                'Lead',
                'ViewContent',
                'Search',
                'AddToCart',
                'AddToWishlist',
                'InitiateCheckout',
                'AddPaymentInfo',
                'Other',
            ],
            fatalApiError: false,
            headerRows: Array.from({ length: 20 }, (_, i) => i + 1),
            legalAgreementsDialogActive: false,
            valid: false,
            file: null,
            fieldDescriptionsSlideoutOpen: false,
            requiredRules,
            eventsAgreementVerified: false,
            notification: '',
            notificationType: '',
            fields: [
                {
                    id: 'source',
                    header: 'Source',
                    value: 'source',
                    align: 'left',
                    width: '8%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'created_at',
                    header: 'Upload Time',
                    value: 'created_at.date',
                    align: 'left',
                    width: '12%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/tools/OfflineEventDate.vue'),
                },
                {
                    id: 'events_processed_at',
                    header: 'Processed at',
                    value: 'events_processed_at.date',
                    align: 'left',
                    width: '12%',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/tools/OfflineEventDate.vue'),
                    props: {
                        type: 'processed'
                    }
                },
                {
                    id: 'filename',
                    header: 'File/Upload Tag',
                    value: 'filename',
                    align: 'left',
                    width: '24%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'status_props',
                    header: 'Status',
                    value: 'status_props.color',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/tools/OfflineEventStatus.vue'),
                },
                {
                    id: 'preProcessing',
                    header: 'Pre-Processing',
                    value: 'preProcessing',
                    align: 'left',
                    width: '13%',
                    sortable: false,
                    type: 'single',
                },
                {
                    id: 'matches',
                    header: 'Matches',
                    value: 'matches',
                    align: 'left',
                    width: '13%',
                    sortable: false,
                    type: 'single',
                },
                {
                    id: 'match_rate',
                    header: 'Match Rate',
                    value: 'match_rate.value',
                    align: 'center',
                    width: '8%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/tools/OfflineEventRate.vue'),
                },
            ],
            // Mapping data
            fileRows: [],
            fileHeaders: [],
            fileMapping: {},
            fileUnhandledFields: [],

            presetFieldMappings: ['Default', 'VinSolutions', 'DealerSocket', 'eLeads'],
            presetFieldMapping: 'Default',
            loadingEvents: false,
            events: [],
            sendNotification: false,
            limitedDataUse: false,
            uploadFieldName: 'file',
            files: [],
            uploadCsvErrors: [],
            uploadCsvWarnings: [],
            successFulUploadRows: 0,
            eventName: 'Purchase',
            headerRow: 1,
            previewErrors: false,
            errorCheckPassed: false,
            errorCheckReviewed: false,
            selected: '',
            emails: [],
            viewingErrorsWarning: false,
            viewingErrorsFatal: true,
            csvDeploying: false,
            csvDeployLoading: false,
            csvDeployProgress: 0,
            csvDeployProgressMessage: '',
            csvDeploySuccessful: false,
            seeErrorDetails: false,
            fileCsvName: '',
            showRatingSystemSlide: false,
            showEstimatedMatchRateSlide: false,
            totalValidated: 0,
            matchCount: 0,
            estimatedMatchRate: false,
            fileUploadedRating: '',
            lastUploadEvent: {},
            unmappedColumns: [],
            deployFileWithUnhandledFields: false,
            monthAndData: [],
            styleObject: {
                noUpload: '#f6685f',
                preLastUpload: '#DCF7FF',
            },
            months: {
                0: 'Jan',
                1: 'Feb',
                2: 'Mar',
                3: 'Apr',
                4: 'May',
                5: 'Jun',
                6: 'Jul',
                7: 'Aug',
                8: 'Sep',
                9: 'Oct',
                10: 'Nov',
                11: 'Dec',
            },
            dataFromLastUpload: [],
            monthsWithoutUpload: 0,
            csvFields,
            presetSupportArticles: null,
            watchMoreHelpSection: false,
            showUnhandledFieldsDialog: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'userIsClient',
            'dealerHasAdProduct',
            'dealerFacebookAdAccountUrl',
            'dealerFacebookAdAccountUrlFiltered',
            'dealerFacebookOfflineEventSetAnnotation',
        ]),
        showHelpSection: {
            get() {
                return !this.$store.state.settings.userData?.hideOfflineEventsHelp || false;
            },
            set(value) {
                this.$store.dispatch('updateUserData', {
                    hideOfflineEventsHelp: !value
                });
            }
        },
        dataUsageLink() {
            return this.currentAgency.configuration?.terms ?? 'https://buyerbridge.io/our-terms/';
        },
        presetSupportArticle() {
            return presetSupportArticles[this.presetFieldMapping];
        },
        fileName() {
            return this.file?.name;
        },
        uploadsContainEvents() {
            return this.events.some((event) => {
                return event.upload_status?.valid_entries > 0;
            });
        },
        lastUpload() {
            return moment(this.lastUploadEvent.created_at?.date).format('MM/DD/YYYY');
        },
    },
    async created() {
        await this.$store.dispatch('waitForDealerLoaded');

        await this.getEvents();
        this.getLastUploadEvent();
        this.periodWithoutData();
    },
    methods: {
        getLastUploadEvent() {
            if (!this.events.length) {
                this.lastUploadEvent = {};
            } else {
                this.lastUploadEvent = this.events.reduce((a, b) => (
                    a.created_at.date > b.created_at.date ? a : b
                ));
            }
        },
        scrollTo(refName) {
            let element = this.$refs[refName];
            element.scrollIntoView({ offset: -150, behavior: 'smooth' });
        },
        async deployCsvFileWithUnhandledFields() {
            this.deployFileWithUnhandledFields = true;
            this.showUnhandledFieldsDialog = false;
            await this.deployNewCsvFile();
        },
        async deployNewCsvFile() {
            this.csvDeploySuccessful = false;
            this.previewErrors = true;
            this.fileCsvName = this.file.name;

            // Determine if there's any unhandled fields
            if (this.fileUnhandledFields.length && !this.deployFileWithUnhandledFields) {
                this.showUnhandledFieldsDialog = true;
                return;
            }

            await this.deployCsvFile();

            // Expand warnings if we're previewing and there's no fatal errors
            if (this.uploadCsvWarnings.length && !this.uploadCsvErrors.length) {
                this.viewingErrorsWarning = true;

                // Scroll to the warnings area after it's displayed
                await this.$nextTick();
                this.$scrollTo(this.$refs.warningsAndErrors, 500, {
                    offset: -150
                });
            }
        },
        onFileParsed({ data = [] }) {
            this.fileHeaders = data[this.headerRow - 1] || [];
            this.fileRows = (this.headerRow >= 2) ? data.splice(this.headerRow, data.length) : data;
            if (typeof this.fileHeaders[0] !== 'undefined') {
                if (this.fileHeaders[0] === this.fileRows[0][0]) {
                    this.fileRows.splice(0, 1);
                }
            }
        },
        async deployReviewedCsvFile() {
            this.errorCheckReviewed = true;
            this.previewErrors = false;
            await this.deployCsvFile();
            await this.handleSuccessfulDeploy();
        },
        async deployCsvFile() {
            try {
                this.csvDeployLoading = true;

                if (this.previewErrors) {
                    this.csvDeployProgressMessage = 'Validating file before sending to Facebook';
                } else {
                    this.csvDeployProgressMessage = 'Uploading file to Facebook';
                }

                const additionalData = {
                    header_row: (this.headerRow >= 1) ? this.headerRow : 0,
                    preview_errors: this.previewErrors,
                    event_name: this.eventName,
                    enable_ldu: (this.currentDealer.facebook_dpo !== null) ? this.currentDealer.facebook_dpo.includes('LDU') : false
                };

                if (!isEmpty(this.fileMapping)) {
                    additionalData.mappings = JSON.stringify(this.fileMapping);
                }

                if (this.emails.length) {
                    additionalData.recipients = JSON.stringify(this.emails);
                }

                const formData = new FormData();
                formData.append('file', this.file);

                for (let key in additionalData) {
                    formData.append(key, additionalData[key]);
                }

                const config = {
                    onUploadProgress: this.handleUploadProgress.bind(this),
                };
                const url = `/facebook_offline_event_set_annotations/${this.dealerFacebookOfflineEventSetAnnotation.id}/upload-csv`;
                const response = await this.$http.axios.post(url, formData, config)
                    .catch(function(error) {
                        console.log(error.response.data.error.messages.pop());
                    });

                this.uploadCsvErrors = [];
                this.uploadCsvWarnings = [];

                if (this.previewErrors) {
                    if (response.data.fb_validation_response.total_validated_count) {
                        this.totalValidated = response.data.fb_validation_response.total_validated_count;
                        this.matchCount = response.data.fb_validation_response.match_count;
                        this.calculateUploadedEstimatedMatchRate();
                    }
                }

                // Process errors
                if (response.data.row_errors.length) {
                    response.data.row_errors.forEach((error) => {
                        if (error.level === 'fatal') {
                            this.uploadCsvErrors.push(error);
                        }
                        if (error.level === 'warning') {
                            this.uploadCsvWarnings.push(error);
                        }
                    });
                }

                //calculate successful rows
                this.successFulUploadRows =
                    (this.fileRows.length -
                        (this.uploadCsvWarnings.length +
                            this.uploadCsvErrors.length)
                    );
                this.calculateUploadedFiledRating();

            } catch (error) {
                console.log('Error Upload CSV', error);
                this.fatalApiError = true;
                this.scrollTo('intructions-ref', { offset: -150 });
            } finally {
                this.csvDeployLoading = false;
                this.csvDeploying = true;
            }
        },
        handleUploadProgress(progressEvent) {
            const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );

            // Use the first half of the loader if we're previewing errors
            // this way we can use the other half to show it uploading
            // if there's no errors
            if (this.previewErrors) {
                this.csvDeployProgress = progress / 2;
            }
                // Use the second half of the loader to upload for real
            // If there's no errors in the previous upload
            else if (this.errorCheckPassed) {
                this.csvDeployProgress = progress / 2 + 50;
            }
            // Otherwise show a full progress bar
            else {
                this.csvDeployProgress = progress;
            }
        },
        async handleSuccessfulDeploy() {
            this.reset();
            this.csvDeploySuccessful = true;
            setTimeout(async() => await this.getEvents(), 5000);
            // Wait for next render to scroll since the target
            // will jump on reset
            await this.$nextTick();
            this.$scrollTo(this.$refs.scrollTarget, 500, { offset: -150 });
            // Keeps querying FB to check if the file has failed.
            executeWithExponentialBackoff(this.getEvents, 4, 30000);
        },
        reset() {
            // File management
            this.file = null;
            this.fileRows = [];
            this.fileHeaders = [];
            this.fileMapping = {};
            this.fileUnhandledFields = [];

            this.eventsAgreementVerified = false;

            // Unhandled columns
            this.deployFileWithUnhandledFields = false;
            this.showUnhandledFieldsDialog = false;

            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.seeErrorDetails = false;
            this.previewErrors = true;
            this.errorCheckReviewed = false;
            this.errorCheckPassed = false;
            this.uploadCsvErrors = [];
            this.uploadCsvWarnings = [];

            this.csvDeploying = false;
            this.csvDeployProgress = 0;
            this.csvDeployProgressMessage = '';
            this.viewingErrorsFatal = true;
            this.viewingErrorsWarning = false;
        },
        async getEvents() {
            try {
                this.events = [];
                this.loadingEvents = true;

                if (!this.dealerFacebookOfflineEventSetAnnotation) {
                    return;
                }

                const [buyerBridgeUploads, facebookUploads] = await Promise.all([
                    this.getBuyerBridgeUploads(),
                    this.getFacebookUploads()
                ]);


                let mergedUploads = merge(keyBy(facebookUploads, 'upload_tag'), keyBy(buyerBridgeUploads, 'upload_tag'));
                mergedUploads = values(mergedUploads);

                this.events = mergedUploads.map((upload) => {
                    if (upload.source == 'BuyerBridge') {
                        upload.status = upload.preProcessing =
                            `${upload.rows_received} recvd / ${upload.rows_sent} sent (${Math.round((upload.rows_sent * 100) / upload.rows_received)}%)`;
                    } else {
                        upload.preProcessing = '--';
                    }

                    upload.matches = '--';

                    if (upload.upload_status) {
                        upload.matches =
                            upload.upload_status.valid_entries +
                            ' valid / ' +
                            upload.upload_status.matched_entries +
                            ' matched';
                    }

                    upload.status_props = this.getStatusProps(upload);

                    if (upload.upload_status && upload.upload_status.match_rate_approx == -1) {
                        upload.match_rate = {
                            value: this.calculateItemMatchRate(upload),
                            type: 'strict'
                        };
                    } else if (upload.upload_status) {
                        upload.match_rate = {
                            value: upload.upload_status.match_rate_approx,
                            type: 'approx'
                        };
                    } else {
                        upload.match_rate = {
                            value: -1,
                            type: 'empty'
                        };
                    }

                    return upload;
                });
            } catch (err) {
                this.$flash('Error processing your events, please try again in a few minutes or contact support if it persists.', 'red');
                this.notification = err + '!';
                this.notificationType = 'error';
            } finally {
                this.loadingEvents = false;
            }
        },
        async getBuyerBridgeUploads() {
            try {
                const { data } = await this.$apiRepository.getFacebookOfflineEventSetUploads({
                    eventSetId: this.dealerFacebookOfflineEventSetAnnotation.id
                });

                return data.map(upload => {
                    upload.source = 'BuyerBridge';
                    return upload;
                });
            } catch (error) {
                this.$flash('Error processing your events, please try again in a few minutes or contact support if it persists.', 'red');
            }
        },
        async getFacebookUploads() {
            try {
                const response = await this.$apiRepository.facebookGraphApiGet({
                    dealerId: this.currentDealer.id,
                    node: 'offline-event-set/uploads',
                    payload: {
                        fields: [
                            'valid_entries',
                            'creation_time',
                            'event_stats',
                            'event_time_max',
                            'event_time_min',
                            'first_upload_time',
                            'match_rate_approx',
                            'matched_entries',
                            'duplicate_entries',
                            'upload_tag'
                        ].join(','),
                        limit: 500
                    }
                });

                if (response.data) {
                    return response.data
                        .map(upload => {

                            const date = moment(upload.creation_time * 1000).utc().format('YYYY-MM-DD HH:mm:ss');

                            return {
                                source: 'Meta',
                                created_at: { date },
                                upload_status: upload,
                                filename: upload.upload_tag,
                                upload_tag: upload.upload_tag
                            };
                        })
                        // @todo figure out how to better handle these
                        .filter(upload => upload.upload_tag !== 'twilio_leads');
                }
            } catch (error) {
                this.$flash('Error processing your events, please try again in a few minutes or contact support if it persists.', 'red');
            }
        },
        getStatusProps(event) {
            if (event.events_sent_at === null) {
                return {
                    label: 'Failed',
                    color: 'red',
                };
            }

            if (event.upload_status === null) {
                return {
                    label: 'Processing',
                    color: 'orange',
                };
            }

            let processLimit = new Date();
            processLimit.setHours(processLimit.getHours() - 2);
            let eventCreatedAt = new Date(event.created_at.date);
            //orange until processed event count from response equals valid events from status
            //or 2 hours from upload
            if ((typeof event.upload_response !== 'undefined' &&
                typeof event.upload_status !== 'undefined' &&
                event.upload_response.num_processed_entries !== event.upload_status.valid_entries &&
                eventCreatedAt >= processLimit
            )) {
                return {
                    label: 'Processing',
                    color: 'orange',
                };
            }

            return {
                label: 'Complete',
                color: 'green',
            };
        },
        calculateUploadedFiledRating() {
            const warningsPercentage =
                (this.uploadCsvWarnings.length / this.fileRows.length) * 100;
            const errorsPercentage =
                (this.uploadCsvErrors.length / this.fileRows.length) * 100;
            if (this.uploadCsvErrors.length === 0 && warningsPercentage < 5) {
                this.fileUploadedRating = 'AWESOME';
            } else if (errorsPercentage < 5 && warningsPercentage < 10) {
                this.fileUploadedRating = 'GOOD';
            } else if (errorsPercentage < 10 && warningsPercentage < 20) {
                this.fileUploadedRating = 'FAIR';
            } else {
                this.fileUploadedRating = 'POOR';
            }
        },
        calculateUploadedEstimatedMatchRate() {
            this.estimatedMatchRate = Math.round((this.matchCount / this.totalValidated) * 100);
        },
        setMonths() {
            let monthLeftSide = moment()
                .subtract(6, 'months')
                ._d.getMonth();
            let end = 0;
            while (end < 12) {
                this.monthAndData.push({
                    month: this.months[monthLeftSide],
                    index: monthLeftSide,
                    noUpload: false,
                });
                if (monthLeftSide === 11) {
                    monthLeftSide = 0;
                } else {
                    monthLeftSide++;
                }
                end++;
            }
        },
        calculateItemMatchRate({ upload_status: { matched_entries, valid_entries } }) {
            if (matched_entries && valid_entries) {
                return ((matched_entries / valid_entries) * 100).toFixed(2);
            }

            return 0;
        },
        periodWithoutData() {
            this.monthAndData = [];
            let lastUpload = new Date(this.lastUpload).getMonth();
            const today = new Date().getMonth();
            this.setMonths();
            let lastUploadIndex = this.monthAndData.findIndex(
                (item) => item.index === lastUpload
            );
            const todayIndex = this.monthAndData.findIndex(
                (item) => item.index === today
            );

            if (lastUpload === today) {
                this.monthAndData[lastUploadIndex].noDataFor = false;
            }
            this.monthAndData.map((currentMonth, idx) => {
                if (currentMonth.index === lastUpload) {
                    this.monthAndData[idx].isLastUpload = true;
                }
                if (currentMonth.index === today) {
                    this.monthAndData[idx].isToday = true;
                    this.monthAndData[idx].daysOfCurrentMonth = new Date().getDate();
                }
            });

            const nMonths = this.monthAndData.slice(0, lastUploadIndex);
            let count = 0;
            while (lastUploadIndex < todayIndex) {
                this.monthAndData[lastUploadIndex].noUpload = true;

                this.monthAndData[lastUploadIndex].fill = this.styleObject.noUpload;
                lastUploadIndex++;
                count++;
            }
            if (count > 0) {
                this.monthAndData[lastUploadIndex - count].noDataFor = true;
            }
            this.monthsWithoutUpload = count;

            nMonths.map((ele, i) => {
                this.monthAndData[i].fill = this.styleObject.preLastUpload;
                this.monthAndData[i].preUpload = true;
            });
        },
    },
};
</script>

<style lang="scss">
.help-text {
    color: $gray;
    font-size: 0.9rem;
    font-style: italic;
}

.match_rate {
    text-align: center;
}

.warning_error_data_list {
    list-style-type: none;
}

.fatal-error {
    color: red;
}

.filename-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    display: block;
}

.feature-status-heading {
    text-transform: uppercase;
    color: $carnation;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}

.heading-darkorange {
    color: darkorange;
}

.feature-status {
    position: relative;
    padding: 10px 10px 10px 6px;
    font-size: 12px;

    &::before {
        content: "";
        width: 2px;
        background-color: rgba($gray, 0.3);
        top: 0;
        bottom: 0;
        left: 0;
    }

    &.odd {
        background-color: rgba($gray, 0.05);
    }

    .feature-status-error {
        p {
            font-weight: 600;
        }
    }
}

.watch-more-btn {
    justify-content: center;
    align-items: center;
    text-decoration: none;

    i.play-icon {
        width: 40px;
        height: 40px;
        border: 2px solid #00a2ea;
        border-radius: 6px;
    }
}

.watch-more-text {
    font-size: 16px;
    color: #00a2ea;
    font-weight: 600;
    background-color: "red";
}

.intructions-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #4a4a4a;
}

.instruction-step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00a2ea;
    margin-bottom: 20px;
    color: white;

    p {
        padding: 5px;
        text-align: center;
        font-size: 21px;
        font-weight: 800;
    }
}

.medium-image {
    width: 250px;
    height: 130px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.medium-image-v {
    width: 174px;
    height: 130px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.section-column {
    @media screen and (max-width: 760px) {
        flex-direction: column;
    }
}

.see-guide-link {
    font-size: 12px;
    font-weight: 400;
}

.validate-process-btn {
    font-size: 20px;
    font-weight: 600;
}

.unmapped-sub-headline {
    font-size: 23px;
    color: $gray-dark;
    font-weight: 400;
}

.unmapped-headline {
    font-size: 68px;
}

.checking-message {
    color: $gray-dark;
    line-height: 24px;
    font-size: 14px;
}

.unmapped-column-list {
    margin: 0 auto;
    width: 30%;

    p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
    }
}

.file-csv-icon {
    color: $gray-dark;
    font-size: 16px;
    text-overflow: ellipsis;
}

.file-rows-status {
    a {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }

    .file-rows-success {
        color: $success-500;
    }

    .file-rows-warnings {
        color: $warning-500;
    }

    .file-rows-errors {
        color: $error-500;
    }
}

.uploaded-file-wrap {
    p {
        font-size: 16px;
    }
}

.upload-status-wrapper {
    margin: 25px;
    padding: 55px 0;
}

.upload-status-month {
    height: 32px;
    border-right: 1px dashed #d0d0d0;

    .lastUpload {
        position: absolute;
        left: -30px;
        top: -70px;
    }

    .todayText {
        position: absolute;
        left: -20px;
        top: -40px;
    }

    .no-data-for {
        position: absolute;
        text-align: center;
        left: 20px;
        bottom: -50px;
        width: 200px;
        font-size: 13px;
        z-index: 2;

        .no-data-text {
            color: $carnation;
        }
    }

    .last-upload-more-six-months {
        position: absolute;
        top: -35px;
        left: 0px;
        width: 300px;
    }

    &.isLastUpload {
        &::before {
            content: "";
            position: absolute;
            height: 60px;
            top: -28px;
            background-color: black;
            width: 3px;
        }
    }

    &.isToday {
        &::after {
            content: "";
            position: absolute;
            height: 50px;
            top: -18px;
            background-color: black;
            width: 3px;
        }
    }

    &.halfOfPeriod {
        &::before {
            content: "";
            position: absolute;
            height: 40px;
            margin-left: 100%;
            background-color: #ffa9a9;
            width: 3px;
        }
    }
}

.styled-table-native {
    tbody {
        tr {
            font-size: 12px;

            &:nth-of-type(2n) {
                background: $white;
            }
        }
    }
}

#upload-status-table {
    .filter-row-container .sort-by {
        width: 200px;
    }
}
</style>
